<template>
<v-layout>
    <v-flex xs5>
    <v-col>
        <v-row class="align-card-image">
            <v-img v-if="category.image" :src="imagePath(category.image.path)" height="64px" width="64px"></v-img>
        </v-row>
    </v-col>
    </v-flex>
    <v-flex xs7>
    <v-col class="align-card-title">
        <v-row>
            <label class="card-title-font">{{category.title}}</label>
        </v-row>
    </v-col>
    </v-flex>
    <v-menu left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item v-for="(item, index) in icons" :key="index">
                <v-btn fab dark x-small class="ma-2" :style="theme">
                    <v-icon :show="isAllowed(item.permission)" @click="performAction(item,category._id)">{{ item.name }}</v-icon>
                </v-btn>
            </v-list-item>
        </v-list>
    </v-menu>
</v-layout>
</template>

<script>
export default {
    props: ["category", "icons","id"],
};
</script>

